import { createIcon } from "@chakra-ui/react"

export const exitIcon = createIcon({
	displayName: "exit",
	viewBox: "0 0 28 23",
	path: (
		<path
			d="M18.1696 15.0954C17.9882 15.2966 17.7344 15.4175 17.4639 15.4316C17.1933 15.4456 16.9283 15.3516 16.7271 15.1703L13.3216 12.1054C13.2154 12.0097 13.1305 11.8926 13.0723 11.7619C13.0142 11.6312 12.9841 11.4898 12.9841 11.3467C12.9841 11.2037 13.0142 11.0622 13.0723 10.9315C13.1305 10.8008 13.2154 10.6838 13.3216 10.588L16.7271 7.52307C16.8261 7.42995 16.9428 7.35754 17.0702 7.31009C17.1976 7.26263 17.3332 7.24109 17.4691 7.24672C17.6049 7.25235 17.7383 7.28504 17.8613 7.34287C17.9844 7.4007 18.0947 7.48251 18.1857 7.58351C18.2767 7.68451 18.3467 7.80266 18.3914 7.93104C18.4362 8.05942 18.4549 8.19545 18.4464 8.33115C18.438 8.46685 18.4025 8.59949 18.3421 8.72131C18.2817 8.84312 18.1976 8.95165 18.0947 9.04054L16.6671 10.3251L21.4974 10.3251C21.7684 10.3251 22.0282 10.4327 22.2198 10.6243C22.4114 10.8159 22.5191 11.0758 22.5191 11.3467C22.5191 11.6177 22.4114 11.8775 22.2198 12.0691C22.0282 12.2607 21.7684 12.3683 21.4974 12.3683L16.6671 12.3683L18.0947 13.6529C18.2959 13.8343 18.4168 14.0881 18.4308 14.3586C18.4449 14.6292 18.3509 14.8942 18.1696 15.0954ZM0.724197 4.19527C0.724197 3.70334 0.821091 3.21623 1.00934 2.76174C1.1976 2.30726 1.47353 1.8943 1.82137 1.54646C2.16922 1.19861 2.58217 0.92268 3.03666 0.734426C3.49114 0.546173 3.97826 0.44928 4.47019 0.44928L24.2218 0.44928C24.7137 0.44928 25.2008 0.546173 25.6553 0.734426C26.1098 0.92268 26.5228 1.19861 26.8706 1.54646C27.2184 1.8943 27.4944 2.30726 27.6826 2.76174C27.8709 3.21623 27.9678 3.70334 27.9678 4.19527L27.9678 18.4981C27.9678 19.4916 27.5731 20.4445 26.8706 21.147C26.1681 21.8495 25.2153 22.2441 24.2218 22.2441L4.47019 22.2441C3.47669 22.2441 2.52388 21.8495 1.82137 21.147C1.11886 20.4445 0.724197 19.4916 0.724197 18.4981L0.724197 4.19527ZM10.2567 2.49255L10.2567 20.2009L24.2218 20.2009C25.1617 20.2009 25.9245 19.4381 25.9245 18.4981L25.9245 4.19527C25.9245 3.25537 25.1617 2.49255 24.2218 2.49255L10.2567 2.49255Z"
			fill="#CBD8F4"
		/>
	),
})

export const largeArrowIcon = createIcon({
	displayName: "largearrow",
	viewBox: "0 0 50 24",
	path: (
		<path
			d="M48.6032 13.0534C49.1889 12.4675 49.1887 11.5178 48.6028 10.9321L39.0554 1.38763C38.4695 0.801938 37.5198 0.802088 36.9341 1.38797C36.3484 1.97385 36.3485 2.92359 36.9344 3.50929L45.421 11.9932L36.9371 20.4798C36.3514 21.0657 36.3515 22.0155 36.9374 22.6012C37.5233 23.1869 38.473 23.1867 39.0587 22.6008L48.6032 13.0534ZM0.806388 13.5002L47.5426 13.4929L47.5421 10.4929L0.805917 10.5002L0.806388 13.5002Z"
			fill="white"
		/>
	),
})
